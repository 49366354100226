import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './common/header'; // Import your Header component
import Footer from './common/footer';
import Landing from './landing'; // Import your landing page or other pages
import Signup from './authentication/signup';
import Login from './authentication/login';
import { AuthProvider } from './authContext'; // Import your AuthProvider from the context file
import SwiftCart from './SwiftCartComponent';
import AdminPortal from './AdminPortalComponent';

const Layout = () => {

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
			setLoading(false);
		  }, 3000);
  })
  return (
    <AuthProvider> {/* Wrap your entire application with AuthProvider */}
      <Header />
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard"  element={<SwiftCart />} />
        <Route path="/adminPortal" element={<AdminPortal />} />

        {/* Other routes */}
      </Routes>
      {loading ? (<p></p>):(<Footer />)}
    </AuthProvider>
  );
};

export default Layout;
