import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../commonStyle.css';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="mt-5">
      <Container>
        <Row>
          <Col>
            <p>&copy; {year} RandomFlies. All Rights Reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
