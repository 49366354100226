import React, { useState } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {firebaseApp} from './authentication/firebase/firebaseConfig';

// Get the current date
const currentDate = new Date();

// Get the day, month, and year
const day = String(currentDate.getDate()).padStart(2, '0');
const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // January is 0!
const year = currentDate.getFullYear();
// Combine them in the desired format
const formattedDate = `${day}-${month}-${year}`;

const AdmimPortalComponent = () => {
    const [files, setFiles] = useState(null); // For the file
    const [text, setText] = useState(''); // For the text data
    const [uploading, setUploading] = useState(false); // To show loading state
    const storage = getStorage(firebaseApp);

    const handleFileChange = (e) => {
        setFiles([...e.target.files]); // Update the file state
      };
    
      const handleTextChange = (e) => {
        setText(e.target.value); // Update the text state
      };

      const handleUpload = async () => {
        if (!files) return;
        setUploading(true);
    
         // Upload each file
  for (const file of files) {
    const storageRef = ref(storage, `images/${formattedDate}/${file.name}`);
    try {
      const snapshot = await uploadBytes(storageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      console.log('File available at', url);
    } catch (error) {
      console.error("Error uploading file:", file.name, error);
    }
  }

    
        setUploading(false);
      };

      return (
        <div>
          <input multiple type="file" onChange={handleFileChange} />
          <input type="text" value={text} onChange={handleTextChange} placeholder="Enter some text" />
          <button onClick={handleUpload} disabled={uploading}>
            {uploading ? 'Uploading...' : 'Upload'}
          </button>
        </div>
      );
    };
    

export default AdmimPortalComponent;