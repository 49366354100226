import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { AuthContext } from '../../authContext'; // Import your AuthContext
import '../commonStyle.css';

const Header = () => {
  const { isLoggedIn, logout } = useContext(AuthContext);
  const isLoggedInDB = JSON.parse(sessionStorage.getItem('isLoggedIn'));
  const userType = JSON.parse(sessionStorage.getItem('user'));
  const handleLogout = async () => {
    await logout(); // Call the logout function from the context
    sessionStorage.removeItem('isLoggedIn');
    sessionStorage.removeItem('user');
  };
  const renderAuthLinks = () => {
    if ((isLoggedIn)|| (isLoggedInDB)) {
      return (
        <Nav>
          <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
        </Nav>
      );
    } else {
      return (
        <Nav>
          <Nav.Link as={Link} to="/login">Login</Nav.Link>
          <Nav.Link as={Link} to="/signup">Sign Up</Nav.Link>
        </Nav>
      );
    }
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand className="with-padding-left" as={Link} to="/">RandomFlies</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto with-padding-right">
          <Nav.Link as={Link} to="/">Home</Nav.Link>
          <Nav.Link as={Link} to="/about">About</Nav.Link>
          {userType && <Nav.Link as={Link} to="/adminPortal">Admin</Nav.Link>}
          {renderAuthLinks()}
        </Nav>
      </Navbar.Collapse>
    </Navbar>

  );
};

export default Header;
