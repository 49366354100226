import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layout'; // Import your Layout component

const App = () => {
  return (
    <Router>
      <Layout /> {/* Render the Layout component */}
    </Router>
  );
};

export default App;
