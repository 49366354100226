const getTemperatureActivity = (temperature) => {
    let activity = '';
  
    switch(true) {
      case temperature <= -4:
          activity = 'Ice sculpting in a winter park.';
          break;
      case temperature === -3:
          activity = 'Cross-country skiing through snowy trails.';
          break;
      case temperature === -2:
          activity = 'Making snow angels in fresh snow.';
          break;
      case temperature === -1:
          activity = 'Ice fishing on a frozen lake.';
          break;
      case temperature === 0:
          activity = 'Building a snowman in your yard.';
          break;
      case temperature === 1:
          activity = 'Sipping hot cocoa by a roaring fire.';
          break;
      case temperature === 2:
          activity = 'Winter bird watching with binoculars.';
          break;
      case temperature === 3:
          activity = 'Reading a book under a cozy blanket.';
          break;
      case temperature === 4:
          activity = 'Painting winter landscapes.';
          break;
      case temperature === 5:
          activity = 'Photographing frost patterns.';
          break;
      case temperature === 6:
          activity = 'Going for a crisp morning walk.';
          break;
      case temperature === 7:
          activity = 'Starting an indoor herb garden.';
          break;
      case temperature === 8:
          activity = 'Knitting warm scarves and hats.';
          break;
      case temperature === 9:
          activity = 'Baking bread on a chilly day.';
          break;
      case temperature === 10:
          activity = 'Bird watching during early spring.';
          break;
      case temperature === 11:
          activity = 'Planting spring bulbs in the garden.';
          break;
      case temperature === 12:
          activity = 'Having a picnic in the park.';
          break;
      case temperature === 13:
          activity = 'Flying a kite on a breezy day.';
          break;
      case temperature === 14:
          activity = 'Going for a bike ride along country lanes.';
          break;
      case temperature === 15:
          activity = 'Attending a spring festival.';
          break;
      case temperature === 16:
          activity = 'Enjoying an outdoor cafe.';
          break;
      case temperature === 17:
          activity = 'Playing frisbee in the park.';
          break;
      case temperature === 18:
          activity = 'Gardening and tending to flowers.';
          break;
      case temperature === 19:
          activity = 'Painting or drawing outside.';
          break;
      case temperature === 20:
          activity = 'Going for a swim in an outdoor pool.';
          break;
      case temperature === 21:
          activity = 'Having a barbecue with friends.';
          break;
      case temperature === 22:
          activity = 'Hiking in a nearby forest.';
          break;
      case temperature === 23:
          activity = 'Taking a road trip to the countryside.';
          break;
      case temperature === 24:
          activity = 'Sunbathing at the beach.';
          break;
      case temperature === 25:
          activity = 'Exploring a new city on foot.';
          break;
      case temperature === 26:
          activity = 'Watching a sunset from a hilltop.';
          break;
      case temperature === 27:
          activity = 'Attending an outdoor concert.';
          break;
      case temperature === 28:
          activity = 'Playing beach volleyball.';
          break;
      case temperature === 29:
          activity = 'Going on a boat ride.';
          break;
      case temperature === 30:
          activity = 'Trying water skiing or wakeboarding.';
          break;
      case temperature === 31:
          activity = 'Visiting a water park.';
          break;
      case temperature === 32:
          activity = 'Enjoying tropical fruit ice cream.';
          break;
      case temperature === 33:
          activity = 'Having a night-time pool party.';
          break;
      case temperature === 34:
          activity = 'Stargazing in a clear night sky.';
          break;
      case temperature === 35:
          activity = 'Relaxing in a hammock with a good book.';
          break;
      default:
          activity = 'Check the weather and dress appropriately!';
  }
  
  
    return activity;
  };
  
  export default getTemperatureActivity;
  