import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


const initializeGA = () => {
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=G-9MRE4T1VJ3`;
  script.async = true;
  document.head.appendChild(script);
/* eslint-disable no-undef */

  window.dataLayer = window.dataLayer || [];
  function gtag() { dataLayer.push(arguments); }
  gtag('js', new Date());
  gtag('config', 'G-9MRE4T1VJ3');
};
/* eslint-enable no-undef */

const root = ReactDOM.createRoot(document.getElementById('root'));
initializeGA();
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
