import React, { createContext, useState, useEffect, useContext } from 'react';
import { signOut } from 'firebase/auth'; // Import Firebase signOut method
import { auth } from './authentication/firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { onAuthStateChanged } from 'firebase/auth'; // Import Firebase authentication method



export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate(); // Get the navigate function

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      const isLoggedInDB = JSON.parse(sessionStorage.getItem('isLoggedIn'));
      const isAdmin = JSON.parse(sessionStorage.getItem('user'));
      const currentPath = window.location.pathname;
      if ((user && isLoggedIn)||(isLoggedInDB)) {
        if (currentPath === '/login' || currentPath === '/signup') {
          navigate('/dashboard');
        } else if ((currentPath === '/adminPortal') && (isAdmin)) {
          navigate('/adminPortal');
        } else {
          navigate('/dashboard');
        }
    
      } else {
        if (currentPath !== '/login' && currentPath !== '/signup') {
          navigate('/'); // Redirect to login if not logged in and not on login/signup page
        }
      }
    });

    return () => unsubscribe();
  }, [navigate, isLoggedIn]);
  
  const toggleLogin = () => {
    setLoggedIn((prevLoggedIn) => !prevLoggedIn);
  };

  const logout = async () => {
    try {
      // Use Firebase signOut method here
      await signOut(auth); // Replace 'auth' with your Firebase authentication instance
      setLoggedIn(false);
      navigate('/'); // Redirect to the landing page after logout
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, toggleLogin, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
