import React, { useContext,useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { auth } from '../firebase/firebaseConfig';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { AuthContext } from '../../authContext';
import { useNavigate } from 'react-router-dom';
import '../../common/commonStyle.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [resetEmail, setResetEmail] = useState('');
  const [resetError, setResetError] = useState(null);
  const [resetSuccess, setResetSuccess] = useState(false);
  const navigate = useNavigate(); // Use React Router's useHistory hook for navigation
  const { toggleLogin } = useContext(AuthContext); // Ensure setIsLoggedIn is part of your AuthContext

  function checkAdminClaim(user) {
    user.getIdTokenResult()
      .then((idTokenResult) => {
        // Confirm the user is an Admin.
        if (!!idTokenResult.claims.admin) {
          // User is an admin
          console.log("User is an admin");
          sessionStorage.setItem('user', JSON.parse(true));
        } else {
          // User is not an admin
          console.log("User is not an admin");
        }
      })
      .catch((error) => {
        console.log("Error retrieving admin claim", error);
      });
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      // Login successful
      console.log(userCredential.user);
      checkAdminClaim(userCredential.user);
      toggleLogin(); // Set the isLoggedIn state to true upon successful login
      sessionStorage.setItem('isLoggedIn', JSON.stringify('true'));
      navigate('/dashboard'); // Replace '/dashboard' with your desired route
    } catch (error) {
      setError(error.message);
      console.error('Error signing in:', error);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetSuccess(true);
      setResetError(null);
    } catch (error) {
      setResetSuccess(false);
      setResetError(error.message);
      console.error('Error sending reset email:', error);
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col xs={12} md={6}>
          <div className="p-4 shadow rounded bg-light">
            <h2 className="text-center mb-4">Login</h2>
            {error && <p className="text-danger">{error}</p>}
            <Form onSubmit={handleLogin}>
              <Form.Group controlId="formLoginEmail">
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <br />
              <Form.Group controlId="formLoginPassword">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <br />
              <Button variant="primary" type="submit" block="true">
                Login
              </Button>
              <Link to="/signup" className="float-inline-end">Don't have an account</Link>
            </Form>
            <h2 className="text-center mt-4">Forgot Password</h2>
            {resetSuccess && <p>Reset email sent successfully!</p>}
            {resetError && <p className="text-danger">{resetError}</p>}
            <Form onSubmit={handleResetPassword}>
              <Form.Group controlId="formResetEmail">
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                />
              </Form.Group>
              <br />
              <Button variant="primary" type="submit" block="true">
                Reset Password
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
