import React, { useEffect, useState } from 'react';
import { firebaseApp } from '../authentication/firebase/firebaseConfig';
import { getStorage, ref as storageRef, getDownloadURL, listAll } from 'firebase/storage';
import { Container, Row, Col } from 'react-bootstrap';

const PlaceholderImage = ({ onLoad, alt }) => (
  <img
    style={{ width: '100%', opacity: 0, transition: 'opacity 0.5s ease-in-out' }}
    className='pb-3'
    alt={alt}
    onLoad={onLoad}
  />
);

const Landing = () => {
  const [publicUrl, setPublicUrl] = useState([]);
  const [loadedImages, setLoadedImages] = useState([]); // Initialize as empty array

  const uniqueUrls = new Set();

  useEffect(() => {
    const storage = getStorage(firebaseApp);
    const imageRef = storageRef(storage, 'public/');
    console.log(imageRef);

    listAll(imageRef)
      .then((res) => {
        res.items.forEach((itemRef, index) => {
          getDownloadURL(itemRef).then((url) => {
            if (!uniqueUrls.has(url)) {
              uniqueUrls.add(url);
              setPublicUrl((prev) => [...prev, url]);
              setLoadedImages((prev) => [...prev, false]); // Initialize as not loaded
            }
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, []);

  const handleImageLoad = (index) => {
    const updatedLoadedImages = [...loadedImages];
    updatedLoadedImages[index] = true;
    setLoadedImages(updatedLoadedImages);
  };

  return (
    <div>
      <br />
      <Container>
        <Row>
          {publicUrl.map((url, index) => (
            <Col key={index} sm={3}>
              {loadedImages[index] !== undefined ? ( // Check if loadedImages[index] is not undefined
                <img
                  style={{ width: '100%', opacity: 1, transition: 'opacity 0.5s ease-in-out' }}
                  className='pb-3'
                  src={url}
                  srcSet={`${url.replace(/(\.\w+)/, '-320w$1')} 320w,
                           ${url.replace(/(\.\w+)/, '-480w$1')} 480w,
                           ${url} 800w`}
                  sizes="(max-width: 320px) 280px,
                         (max-width: 480px) 440px,
                         800px"
                  alt={url}
                  loading="lazy"
                />
              ) : (
                <PlaceholderImage onLoad={() => handleImageLoad(index)} alt={url} />
              )}
            </Col>
          ))}
        </Row>
      </Container>
      <br />
    </div>
  );
};

export default Landing;
