import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { auth, firebaseApp } from '../firebase/firebaseConfig';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, setDoc} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';


const Signup = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Use React Router's useHistory hook for navigation


  const handleSignup = async () => {
    if (!firstName || !lastName || !email || !password) {
      alert('Please enter both first name and last name.');
      return;
    }
    try {

      const userData = {
        firstName,
        lastName,
        email,
        // Add more user properties as needed
      };

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      // User signed up successfully, now create user document in Firestore
      const db = getFirestore(firebaseApp); // Get the Firestore instance

      // Replace 'users' with your Firestore collection name
      const userRef = doc(db, 'users', userCredential.user.uid);
      
      await setDoc(userRef, userData);
      setFirstName('');
      setLastName('');
      setEmail('');
      setPassword('');

      // User signed up successfully
      navigate('/login'); // Replace '/dashboard' with your desired route

      
      // You can navigate to a different page or handle the signup success here
    } catch (error) {
      // Handle signup errors
      console.error('Signup error:', error.message);
    }
  };


  return (
    <Container className="mt-5">
    <Row className="justify-content-center">
      <Col xs={12} md={6}>
        <div className="p-4 shadow rounded bg-light">
          <h2 className="text-center mb-4">Signup</h2>
          <Form>
            <Form.Group controlId="formFirstName">
              <Form.Control
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formLastName">
              <Form.Control
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formEmail">
              <Form.Control
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formPassword">
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <br />
            <Button variant="primary" onClick={handleSignup} block="true">
              Signup
            </Button>
            <Link className="float-inline-end" to="/login">Already have an account</Link>
          </Form>
        </div>
      </Col>
    </Row>
  </Container>
  );
};

export default Signup;