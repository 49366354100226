import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc as firestoreDoc, getDoc as firestoreGetDoc } from 'firebase/firestore';


const firebaseConfig = {
  // Your Firebase config object here
  apiKey: "AIzaSyBL0cT0EfJ1826HjPY2e_KsxatCyNEF354",
  authDomain: "swiftcart-b6706.firebaseapp.com",
  projectId: "swiftcart-b6706",
  storageBucket: "gs://swiftcart-b6706.appspot.com",
  messagingSenderId: "584520645279",
  appId: "1:584520645279:web:bc1cf9404c9a4e42f240aa",
  measurementId: "G-9MRE4T1VJ3"
};

// Initialize Firebase app
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp); // Initialize Firestore

// Exporting the Firestore functions
const doc = firestoreDoc;
const getDoc = firestoreGetDoc;

export { firebaseApp, auth, db, doc, getDoc };
