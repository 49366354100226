import React, { useEffect, useState, useLayoutEffect, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { auth, db, doc, getDoc, firebaseApp } from './authentication/firebase/firebaseConfig';
import { getDatabase, ref as dbReference, child, get } from "firebase/database";
import Location from './LocationComponent';
import { onAuthStateChanged } from 'firebase/auth'; // Import Firebase authentication method
import { getStorage, ref as storeageRef, getDownloadURL, listAll } from "firebase/storage";
import { Container, Row, Col } from 'react-bootstrap';

const SwiftCart = () => {

	const [inputValue, setInputValue] = useState(''); // State to hold input value
	const [items, setItems] = useState([]); // State to hold the list of items
  const [storeItemsList, setStoreItemsList] = useState([]);
  const [userDataList, setUserDataList] = useState([]);
  const [images, setImages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [chatGPTResponse, setChatGPTResponse] = useState('');
  const [error, setError] = useState('');
  const uniqueUrls = new Set();

  const navigate = useNavigate();

  // const storage = getStorage(auth);
  var storage = getStorage(firebaseApp);
  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleFetchChatGPT = async () => {
    try {
      const response = await fetch(
        'https://us-central1-swiftcart-b6706.cloudfunctions.net/app/api/openAI', // Replace with your actual Cloud Function URL
        {
          method: 'POST',
          mode: 'cors',
          // credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'Headers'
          },
          body: JSON.stringify( {name: userInput} ),
        }
      );
        console.log("hey boy",JSON.stringify( {name: userInput} ));
        console.log(response);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const result = await response.json();
      setChatGPTResponse(result.msg);
      setError('');
    } catch (error) {
      console.error('Error:', error.message);
      setChatGPTResponse('');
      setError('Internal Server Error');
    }
  };

  const fetchImages = useCallback(async (directoryRef) => {
    try {
      const result = await listAll(directoryRef);
      result.items.forEach((fileRef) => {
        getDownloadURL(fileRef).then((url) => {
          // setImages((prev) => [...prev, url]);
          if (!uniqueUrls.has(url)) {
            uniqueUrls.add(url);
            setImages((prev) => [...prev, url]);
            }
        });
      });

      for (const subDirRef of result.prefixes) {
        await fetchImages(subDirRef);
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
    // eslint-disable-next-line 
  },[setImages]);

  const fetchData = (setUserDataList, navigate) => {
    try {
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        console.log(user);
        if (user) {
          const userRef = doc(db, 'users', user.uid);
          const userDocSnap = await getDoc(userRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            console.log('User data:', userData);
            setUserDataList(userData);
            // Handle the user data here
          } else {
            console.log('User document does not exist');
          }
        }
        unsubscribe(); // Unsubscribe once user state changes are handled
      });
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  
  
  useEffect(() => {
    const imagesRef = storeageRef(storage, 'images/');
    fetchImages(imagesRef);
    fetchData(setUserDataList, navigate);
  }, [fetchImages, storage, setUserDataList, navigate]);
  
  useLayoutEffect(() => {
    fetchData(setUserDataList, navigate);
  }, [setUserDataList, navigate]);
  

  // Function to handle adding items
  const handleAddItem = () => {
    if (inputValue.trim() !== '') {
      setItems([...items, inputValue.trim()]); // Add the input value to the items array
      setInputValue(''); // Reset the input field
    }
	};
  
  
	//Function to group the enteredLists with the storeItemsList
	const goSwift = () => {
		console.log(items, storeItemsList);
		const categorizedItems = getCategorizedItems(items, storeItemsList);
    console.log(categorizedItems, Object.keys(storeItemsList));
    // const user = auth.currentUser();
	};

  const getCategorizedItems = (arr, obj) => {
    return Object.keys(obj).reduce((acc, category) => {
      const categoryItems = arr.filter(item => {
        return obj[category].some(product => product.name.toLowerCase() === item.toLowerCase());
      });

      if (categoryItems.length > 0) {
        acc.push({
          category,
          items: categoryItems,
        });
      }

      return acc;
    }, []);
  };


	// Function to handle removing items by index
	const handleRemoveItem = (index) => {
		const updatedItems = [...items];
		updatedItems.splice(index, 1); // Remove the item at the specified index
		setItems(updatedItems); // Update the items array
	};

  useEffect(() => {

		    // Database read operation
				const dbRef = dbReference(getDatabase());
				get(child(dbRef, '/categories')).then((snapshot) => {
					if (snapshot.exists()) {
						setStoreItemsList(snapshot.val());
						console.log(snapshot.val());
					} else {
						console.log("No data available");
					}
				}).catch((error) => {
					console.error(error);
				});

    // Optionally return a cleanup function in useEffect
    return () => {
      // Perform any cleanup if needed
    };
  }, []); // Empty dependency array ensures this runs only once

  return (
    <div>
      <div>
      <h1>ChatGPT Interaction</h1>
      <div>
        <label>
          User Input:
          <input type="text" value={userInput} onChange={handleInputChange} />
        </label>
      </div>
      <div>
        <button onClick={handleFetchChatGPT}>Fetch ChatGPT Response</button>
      </div>
      {chatGPTResponse && (
        <div>
          <h2>ChatGPT Response:</h2>
          <p>{chatGPTResponse}</p>
        </div>
      )}
      {error && (
        <div>
          <h2>Error:</h2>
          <p>{error}</p>
        </div>
      )}
    </div>
      <Container>
        <Row>
        {images.map((url, index) => (
          <Col key={index} sm={4}>
        <img style={{width:'100%'}} className='pb-3' key={index} src={url} alt={`Gallery ${index}`} />
        </Col>
      ))}
        </Row>
      </Container>
      <h1>Hello, I'm a functional component!</h1>
      {/* <img src={imageUrl} alt="Loaded from Firebase" />; */}
      <Location />
			<input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleAddItem();
          }
        }}
      />
			   <button onClick={handleAddItem}>Add</button>
				 <button onClick={goSwift}>GoSwift</button>
      <div>{userDataList.email}{userDataList.firstName}{userDataList.lastName}</div>
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            {item}
            <button onClick={() => handleRemoveItem(index)}>Remove</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SwiftCart;

// https://REGION-YOUR_PROJECT_ID.cloudfunctions.net/YOUR_FUNCTION_NAME

// https://us-central1-swiftcart-b6706.cloudfunctions.net/handleChatGPTRequest
