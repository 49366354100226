import React, { useState, useEffect } from 'react';
import WeatherComponent from './WeatherComponent';
import { fetchIPData } from './sharedFunctions';

const Location = () => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);


  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      }, (error) => {
        if (error.message==='User denied Geolocation') {
          const getCoordinatesFromIP = async () => {
            try {
              let data = await fetchIPData();
              console.log(data);
              setLatitude(data.latitude);
              setLongitude(data.longitude);
            } catch (error) {
              console.error('Error:', error);
            }
          };
        getCoordinatesFromIP(); // Make sure to call the async function 
        }
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, []);

  return (
    <div>
      <h2>Current Location</h2>
      {latitude && longitude ? (
        <div>
          <p>Latitude: {latitude}</p>
          <p>Longitude: {longitude}</p>
        </div>
      ) : (
        <p>Loading location...</p>
      )}
    <WeatherComponent latitude={latitude} longitude={longitude} />

    </div>
  );
};

export default Location;
