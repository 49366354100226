
const getLocationFromIPAddress = async (ipAddress) => {
    const response = await fetch(`https://ipapi.co/${ipAddress}/json/`);
    if (response.ok) {
      const data = await response.json();
      return {
        latitude: data.latitude,
        longitude: data.longitude,
      };
    } else {
      throw new Error('Unable to fetch location information');
    }
  };

export const fetchIPData = async () => {
    try {
        const response = await fetch('https://api64.ipify.org?format=json');
        if (response.ok) {
          const data = await response.json();
          const ipAddress = data.ip;
          const locationInfo = await getLocationFromIPAddress(ipAddress);
          return locationInfo;
        } else {
          console.error('Failed to fetch IP address:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
  };