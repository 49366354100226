import React, { useState, useEffect } from 'react';
import getTemperatureActivity from './temperatureActivities';

const WeatherComponent = ({ latitude, longitude }) => {
  const [weatherData, setWeatherData] = useState(null);
  const [weatherActivity, setWeatherActivity] = useState(null);
  const API_KEY = 'd17733419162eaac4d4b606db0dd0b3a'; // Replace with your OpenWeatherMap API key

  useEffect(() => {
    const fetchWeather = async () => {
      try {
        const response = await fetch(
            `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${API_KEY}&units=metric`
            );
        if (response.ok) {
          const data = await response.json();
          setWeatherData(data);
          console.log(Math.round(data.main.temp));
          const activity = getTemperatureActivity(Math.round(data.main.temp));
          setWeatherActivity(activity);
          console.log(data,data.weather[0].description);
        } else {
          console.error('Weather data not available');
        }
      } catch (error) {
        console.error('Error fetching weather:', error);
      }
    };

    if (latitude && longitude) {
      fetchWeather();
    }
  }, [latitude, longitude]); // Trigger the fetch when latitude or longitude changes

  return (
    <div>
      <h2>Weather Information</h2>
        {weatherData && (
        <div>
            <p>Temperature: {weatherData.main.temp}°C</p>
            <p>Description: {weatherData.weather[0].description}</p>
        <p>Activity: {weatherActivity}</p>
        </div>
        )}
    </div>
  );
};

export default WeatherComponent;
